<!-- <div class="logo"> -->
  <!-- <img src="assets/c-logo.svg?v=1" class="logo-a" alt="logo" width="64" height="64"> -->
<!-- </div> -->

<ng-container *ngIf="currentUser">
  <div #container class="items-container" [class.on-scrolling]="scrolling">
    <a class="item"
       routerLink="/dashboard"
       routerLinkActive="active"
       (click)="resetSearch()">Dashboard
    </a>
    <a class="item"       
       routerLink="/projects"
       routerLinkActive="active">
       Projects
    </a>
    <a class="item"
       [routerLink]="(defaultNestedModeForFeature$ | async)?.['datasets']? '/datasets/simple/*/projects' : '/datasets'"
       [class.active]="(baseRouteConfig$ | async) ==='datasets'"
       >
       Datasets
    </a>
    <a class="item"
       [routerLink]="(defaultNestedModeForFeature$ | async)?.['pipelines']? '/pipelines/*/projects' : '/pipelines'"
       [class.active]="(baseRouteConfig$ | async) ==='pipelines'">
       Pipelines
    </a>
    <a class="item"
       [routerLink]="(defaultNestedModeForFeature$ | async)?.['reports']? '/reports/*/projects' : '/reports'"
       [class.active]="(baseRouteConfig$ | async) ==='reports'"
      >
       Reports
    </a>
    <a class="item"
       routerLink="/workers-and-queues"
       routerLinkActive="active">
       Workers & Queues
    </a>
  </div>
</ng-container>

<div *ngIf="currentUser" class="account">
  <!-- <a *ngIf="environment.slackLink" class="item" [href]="environment.slackLink"
     target="_blank"
     smTooltip="Community support on Slack" [matTooltipShowDelay]="0" matTooltipPosition="right">
    <i class="i-slack-mark-color"></i>
  </a> -->
</div>
